import React from 'react';
import { Link } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import Footer from '../components/Footer';
import LineFull from '../images/linefull.jpg';
import { useAllEvents } from '../hooks/getAllEvents';
import '../styles/app.scss';
import SidebarCategories from '../components/sidebar/Categories';
import Search from '../components/sidebar/Search';
import Archives from '../components/sidebar/Archives';
import Schedule from '../components/sidebar/Schedule';
import SEO from '../components/seo';
import Shop from '../components/sidebar/Shop';

const Events = () => {
    const eventsData = useAllEvents();
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return (
        <>
            <SEO title="Events" />
            <HeaderMain />
            <HeroSingle pageTitle="Events" />

            <section className="page-section">
                <div className="container blogwrapper">
                    <div className="bloglftwrap">
                        {eventsData.map((event, i) => (
                            <div className="bloglft" key={i}>
                                <div className="blogimg">
                                    <img
                                        src={
                                            event.featured_media.localFile
                                                .childImageSharp.fluid.src
                                        }
                                        alt="rocking resources"
                                    />
                                </div>
                                <div className="bloxexc">
                                    <h2
                                        dangerouslySetInnerHTML={{
                                            __html: event.title,
                                        }}
                                    />
                                    <h5>
                                        {
                                            months[
                                            new Date(event.date).getMonth()
                                            ]
                                        }{' '}
                                        {new Date(event.date).getDate()},
                                        {new Date(event.date).getFullYear()}
                                    </h5>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: event.excerpt,
                                        }}
                                    />
                                    <Link to={`/${event.slug}`}>Read More</Link>
                                </div>
                                <img
                                    className="blogline"
                                    src={LineFull}
                                    alt="linefull"
                                />
                            </div>
                        ))}
                    </div>

                    <div className="blogsidebar">
                        <SidebarCategories />
                        <Search />
                        <Archives />
                        <Schedule />
                        <Shop />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default Events;
